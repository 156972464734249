import image1 from "./iphone.png";
import image2 from "./speaker.png";
import image3 from "./tv.png";
import image4 from "./laptop.png";
import image5 from  "./car.png";
import image6 from "./wash.png";
import image7 from "./coin.png";
import image8 from "./trip.png";
import image9 from  "./amazon.png";
import image10 from "./airpod.png";
import background from "./background.jpg";

const IMAGES = {
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    background
}
export default IMAGES