import React, { useState } from 'react'

const Redeem = () => {
  const [windata, setWinData] = useState({
    userId: "jkasgx75hgsxc6u",
    userName: "dipsChowdhury",
    aboutUser: "Hi its dipankar",
    firstName: "Dipankar",
    lastName: "Chowdhury",
    email: "dip.chowdhury@gmail.com",
    phone: "987654321",
    staddress: "108/2, ghogomali main road",
    country: 'India',
    city: "Siliguri",
    state: "West bengal",
    pin: "734001",
    winPrize:"5gram Gold Coin",
    createdAt: new Date()    
  });
  return (
    <div>Redeem</div>
  )
}

export default Redeem