import React, { Component, useState } from "react";
import { GoogleAuthProvider, getAuth, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { Link } from 'react-router-dom';
import WheelComponent from "./weel";
import "react-wheel-of-prizes/dist/index.css";
import "./styles.css";
import IMAGES from "./assets";

import TrPortal from "./portal";
import Confetti from "react-confetti";
import { dataBase } from './firebaseConfig';
import { collection, addDoc, getDocs } from 'firebase/firestore';


export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portal: false,
      show: false,
    };
  }
  
  render() {
    
    let objIndex = {
      "10%offonnecklace": 1, 
      "20%offonearings": 2, 
      "BetterLuckNextTime": 3, 
      "15gramSliverCoins": 4, 
      "BetterLuckNextTime": 5,
      "5gramGoldcoins": 6,
    }
    const segments = [
      "10% off on necklace",
      "20% off on earings",  
      "Better Luck Next Time",
      "15 gram Sliver Coins",
      "Better luck net time",
      "5 gram Gold coins",
    ];

    const weelColors = () => {
      let arr = [];
      let colors = ["#EE4040","#f1860c", "#F0CF50", "#815CD1", "#3DA5E0", "#34A24F"];
      segments.forEach((el) => {
        let color = colors.shift();
        arr.push(color);
        colors.push(color);
      });

      return arr;
    };
    const segColors = weelColors();

    // const rand = () => {
    //   return setTimeout(() => {
    //     return segments[Math.floor(Math.random() * segments.length)];
    //   }, 5000);
    // };
    const onFinished = (winner) => {
      this.setState({ portal: false, show: winner });
    };
    return (
      <div
        // id="pankaj"
        style={{
          display: "flex",
          justifyContent: "center",
          background: `url(${IMAGES.background})`
        }}
      >
        {this.state.show && <Confetti width={1600} height={1019} />}
        <WheelComponent
          segments={segments}
          segColors={segColors}
          winningSegment={"8"}
          onFinished={(winner) => onFinished(winner)}
          primaryColor="black"
          contrastColor="white"
          buttonText="Spin"
          isOnlyOnce={true}
        />
        {this.state.portal ? <TrPortal /> : null}
        {this.state.show && (
          // modal
          <div className="box">
            <div className="imageBox">
              <img className="img-fluid" src={IMAGES[`image${objIndex[this.state.show.split(" ").join("")]}`]} alt="" />
            </div>
            <h2 className="titleWin result">
              CONGRATULATIONS!!! YOU HAVE WON {this.state.show} !!!
            </h2>
            <div className="closeContainer">
              <button
                className="closepankaj"
                onClick={() => this.setState({ show: false })}
              >
                Save Coupon
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Home;