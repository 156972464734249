import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyC4-7PCVvs7dCe7Z93WHoQkUbrJor4yeRg",
  authDomain: "ratnabhandar-942f5.firebaseapp.com",
  projectId: "ratnabhandar-942f5",
  storageBucket: "ratnabhandar-942f5.appspot.com",
  messagingSenderId: "414211620904",
  appId: "1:414211620904:web:2403cc42927394c326b51d",
  measurementId: "G-RG6MH9TPKT"
};

export const app = initializeApp(firebaseConfig);
export const dataBase = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
// const analytics = getAnalytics(app);