import React  from 'react';
import './firebaseConfig';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Redeem from './Redeem';

function App() {
  return (
    <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Home /> }/>
                <Route path="/redeem" element={ <Redeem /> }/>
            </Routes>
        </BrowserRouter>
  );
}

export default App;
